var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      !_vm.param.vendorFlag
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("c-table", {
                ref: "tableRisk",
                attrs: {
                  title: "LBL0001203",
                  tableId: "riskRegister01",
                  columns: _vm.gridRisk.columns,
                  data: _vm.riskReduce.riskbooks,
                  merge: _vm.gridRisk.merge,
                  gridHeight: "auto",
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  editable: false,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "riskHazardPicture"
                            ? [
                                _c("q-icon", {
                                  staticClass: "text-success",
                                  staticStyle: {
                                    "font-size": "30px",
                                    cursor: "pointer",
                                  },
                                  attrs: { name: "image" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openHazardPicture(props)
                                    },
                                  },
                                }),
                              ]
                            : col.name === "picture"
                            ? [
                                _c("q-icon", {
                                  staticClass: "text-primary",
                                  staticStyle: {
                                    "font-size": "30px",
                                    cursor: "pointer",
                                  },
                                  attrs: { name: "image" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openImprPicture(props)
                                    },
                                  },
                                }),
                              ]
                            : col.name === "customCol"
                            ? [
                                _c(_vm.imprComponent, {
                                  tag: "component",
                                  attrs: {
                                    col: col,
                                    props: props,
                                    inputEditable: false,
                                    requestContentsCols:
                                      _vm.requestContentsCols,
                                    tableKey: "ram4mAssessScenarioId",
                                    ibmTaskTypeCd: "ITT0000023",
                                    ibmTaskUnderTypeCd: "ITTU000030",
                                  },
                                  on: { imprChange: _vm.imprChange },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3968100526
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("c-table", {
            ref: "tableImpr",
            attrs: {
              title: "LBL0001174",
              tableId: "impr01",
              columns: _vm.gridImpr.columns,
              data: _vm.riskReduce.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: "auto",
              filtering: false,
              columnSetting: false,
              usePaging: false,
              editable: false,
            },
            on: { linkClick: _vm.linkClick },
            scopedSlots: _vm._u([
              {
                key: "customArea",
                fn: function ({ props, col }) {
                  return [
                    col.name === "riskHazardPicture"
                      ? [
                          _c("q-icon", {
                            staticClass: "text-success",
                            staticStyle: {
                              "font-size": "30px",
                              cursor: "pointer",
                            },
                            attrs: { name: "image" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openHazardPicture(props)
                              },
                            },
                          }),
                        ]
                      : col.name === "picture"
                      ? [
                          _c("q-icon", {
                            staticClass: "text-primary",
                            staticStyle: {
                              "font-size": "30px",
                              cursor: "pointer",
                            },
                            attrs: { name: "image" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openImprPicture(props)
                              },
                            },
                          }),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }